import { ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { theme } from "./Helper/Theme";
import ScrollToTop from "./Helper/ScrollToTop";
import Login from "./Pages/login";
import Signup from "./Pages/signup";
import ForgotPassword from "./Pages/forgot-password";
import Layout from "./Component/Layouts/Layout";
import Dashboard from "./Pages/dashboard";
import { Event } from "./Pages/event";
import { Camera } from "./Pages/camera";
import Alert from "./Pages/alert";
import { Report } from "./Pages/report";
import { Location } from "./Pages/location";
import { AddRoi, Roi } from "./Pages/roi";
import { Area } from "./Pages/area";
import Settings from "./Pages/settings";
import HelpSupport from "./Pages/help-support";
import Profile from "./Pages/profile/Profile";
import "./App.css";
import PrivateRoute from "./Helper/PrivateRoute";
import PageNotFound from "./Pages/PageNotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserManagement from "./Pages/UserManagement";
import Organization from "./Pages/Organization";

import FaceRegDashboard from "./Pages/face-reg/Dashboard";
import FaceRegRegistration from "./Pages/face-reg/Registration";
import FaceRegAttendance from "./Pages/face-reg/Attendance";
import FaceRegReports from "./Pages/face-reg/Reports";
import FireDetections from "./Pages/FireDetection";
import FallDetection from "./Pages/FallDetection";
import DangerDetetcion from "./Pages/DangerDetetcion";
import IntrusionDetection from "./Pages/IntrusionDetection";
import EntryExitMonitoring from "./Pages/Retail-Features/Customer-Traffic-Analysis/TrafficAnalysis";
import HeatMapping from "./Pages/Retail-Features/Heat-Mapping/HeatMapping";
import QueueManagement from "./Pages/Retail-Features/Queue-Management/QueueManagement";
import DemographicAnalysis from "./Pages/Retail-Features/Demographic-Analysis/DemographicAnalysis";
import Safetycompliance from "./Pages/Retail-Features/Safety-and-Compliance/Safetycompliance";
import Interactionanalysis from "./Pages/Retail-Features/Product-Interaction-Analysis/Interactionanalysis";
import RetailDashboard from "./Pages/Retail-Features/Dashboard/RetailDashboard";
import Visiters from "./Pages/Visiters/Visiters";
import Roles from "./Pages/role/Roles";
import Ppe_kit_detection from "./Pages/PPE_kit_detection/Ppe_kit_detection";
import User_Manage from "./Pages/face-reg/User_manage/User_Manage";
import Aleart from "./Pages/face-reg/Aleart/Aleart";
import CloseOfDay from "./Pages/face-reg/CloseOfDay/CloseOfDay";
import Main from "./Pages/face-reg/Standard_management/Main";



const pages = [
  { name: "Dashboard", path: "dashboard", element: <Dashboard /> },
  { name: "Event", path: "event", element: <Event dashboard="" /> },
  { name: "Camera", path: "camera", element: <Camera /> },
  { name: "Visitor", path: "visitor", element: <Visiters /> },
  { name: "Alert", path: "alert", element: <Alert /> },
  { name: "Report", path: "report", element: <Report /> },
  { name: "Location", path: "location", element: <Location /> },
  { name: "Roi", path: "roi", element: <Roi /> },
  { name: "Area", path: "area", element: <Area /> },
  { name: "Settings", path: "settings", element: <Settings /> },
  { name: "HelpSupport", path: "help-support", element: <HelpSupport /> },
  { name: "UserManagement", path: "user-management", element: <UserManagement /> },
  { name: "Organization", path: "organization", element: <Organization /> },
  { name: "Roi", path: "add-roi", element: <AddRoi /> },
  { name: "Roi", path: "edit-roi", element: <AddRoi /> },
  { name: "Fire Detections", path: "fire-detections", element: <FireDetections /> },
  { name: "Fall Detections", path: "fall-detections", element: <FallDetection /> },
  { name: "Danger Detections", path: "danger-detections", element: <DangerDetetcion /> },
  { name: "Intrusion Detections", path: "intrusion-detections", element: <IntrusionDetection /> },
  { name: "FaceRegDashboard", path: "face-reg/dashboard", element: <FaceRegDashboard /> },
  { name: "FaceRegRegistration", path: "face-reg/registration", element: <FaceRegRegistration /> },
  { name: "FaceRegAttendance", path: "face-reg/attendance", element: <FaceRegAttendance /> },
  { name: "FaceRegReports", path: "face-reg/reports", element: <FaceRegReports /> },
  { name: "FaceRegUsermange", path: "face-reg/user-manag", element: <User_Manage /> },
  { name: "FaceRegAlert", path: "face-reg/alert", element: <Aleart /> },
  { name: "FaceRegCloseofday", path: "face-reg/close-of-day", element: <CloseOfDay /> },
  { name: "FaceRegStandard", path: "face-reg/standardmange", element: <Main /> },


  { name: "Dashboard", path: "retailfeatures/dashboard", element: <RetailDashboard /> },
  { name: "Traffic Analysis", path: "retailfeatures/trafficanalysis", element: <EntryExitMonitoring /> },
  { name: "Heat Mapping", path: "retailfeatures/heatmapping", element: <HeatMapping /> },
  { name: "Queue Management", path: "retailfeatures/queuemanagement", element: <QueueManagement /> },
  { name: "Demographic Analysis", path: "retailfeatures/demographicanalysis", element: <DemographicAnalysis /> },
  { name: "Product Interaction Analysis", path: "retailfeatures/interactionanalysis", element: <Interactionanalysis /> },
  { name: "Safety and Compliance", path: "retailfeatures/safety&compliance", element: <Safetycompliance /> },
  { name: "Role", path: "role", element: <Roles /> },
  { name: "PPE-Kit-Detection", path: "ppe-kit-detection", element: <Ppe_kit_detection /> }

];
function App() {
  return (
    <ScrollToTop>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Login />} />
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route element={<PrivateRoute />}>
            {pages.map((page, index) => {
              const { name, path, element } = page;
              return (
                <Route
                  key={index}
                  exact
                  path={`/${path}`}
                  name={name}
                  element={<Layout>{element}</Layout>}
                />
              );
            })}
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <ToastContainer />
      </ThemeProvider>
    </ScrollToTop>
  );
}

export default App;
