import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { ChangePassword, Delete, Logout } from '../modal';
import { AddEvent, ViewEvent } from '../../Pages/event';
import { AddCamera, ViewCamera } from '../../Pages/camera';
import { AddLocation, ViewLocation } from '../../Pages/location';
import { AddRoi, ViewRoi } from '../../Pages/roi';
import { AddArea, ViewArea } from '../../Pages/area';
import { ExportSuccess } from '../../Pages/report';
import AddUser from '../../Pages/UserManagement/AddUser';
import ViewUser from '../../Pages/UserManagement/ViewUser';
import UploadVideo from '../../Pages/alert/UploadVideo';
import AddOrganization from '../../Pages/Organization/AddOrganization';
import ViewOrganization from '../../Pages/Organization/ViewOrganization';
import AlertDetails from '../../Pages/alert/AlertDetails';
import ViewAlert from '../../Pages/alert/ViewAlert';
import AddPerson from '../../Pages/face-reg/Registration/AddPerson';
import ViewAlert1 from '../../Pages/alert/ViewAlert1';
import UserModel from '../../Pages/face-reg/User_manage/UserModel';

export default function CommonModal({ setIsModalOpen, handleClose, eventListDataForSelectBox, modalOpen, closeFlag }) {

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Common_modal"
      open={modalOpen.open}
    >
      {modalOpen.currentComponent === 'changePassword' && (
        <ChangePassword handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'logout' && (
        <Logout handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'delete' && (
        <Delete handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'exportSuccess' && (
        <ExportSuccess handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}

      {modalOpen.currentComponent === 'addEditEvent' && (
        <AddEvent handleClose={handleClose} eventListDataForSelectBox={eventListDataForSelectBox} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'viewEvent' && (
        <ViewEvent handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}

      {modalOpen.currentComponent === 'addEditCamera' && (
        <AddCamera handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'viewCamera' && (
        <ViewCamera handleClose={handleClose} setIsModalOpen={setIsModalOpen} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}

      {modalOpen.currentComponent === 'addEditLocation' && (
        <AddLocation handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'viewLocation' && (
        <ViewLocation handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}

      {modalOpen.currentComponent === 'addEditRoi' && (
        <AddRoi handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'viewRoi' && (
        <ViewRoi handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}

      {modalOpen.currentComponent === 'addEditArea' && (
        <AddArea handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'viewArea' && (
        <ViewArea handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'addUser' && (
        <AddUser handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'viewUser' && (
        <ViewUser handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'UploadVideo' && (
        <UploadVideo handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'addOrganization' && (
        <AddOrganization handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'viewOrganization' && (
        <ViewOrganization handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'alertDetails' && (
        <AlertDetails handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'viewAlert' && (
        <ViewAlert1 handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'viewUsermanage' && (
        <UserModel handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
      {modalOpen.currentComponent === 'addPerson' && (
        <AddPerson handleClose={handleClose} modalOpen={modalOpen} closeFlag={closeFlag} />
      )}
    </Dialog>
  );
}
