import React, { useState } from "react";
import {
  Box,
  ListItemText,
  ListItem,
  ListItemButton,
  ListItemIcon,
  List,
  Divider,
} from "@mui/material";
import {
  dashboard,
  dashboardActive,
  event,
  eventActive,
  camera,
  cameraActive,
  alert,
  alertActive,
  report,
  reportActive,
  location1,
  locationActive,
  roi,
  roiActive,
  area,
  areaActive,
  help,
  help_active,
  queue_icon,
  queue_active,
  queue,
  demographicanalysis,
  demographicanalysis_active,
  safety,
  safety_active,
  Interaction,
  Interaction_active,
  analytics,
  analytics_active,
  heatmap,
  heatmap_active,
  FireDetections,
  FireDetections_active,
  FallDetections,
  FallDetections_active,
  DangerDetections,
  DangerDetections_active,
  IntrusionDetections,
  IntrusionDetections_active,
  visitor,
  visitor_active,
} from "../../Helper/Constant";
import ppekit_active from '../../assets/image/sidebar/ppekit_active.png';
import ppekit from '../../assets/image/sidebar/ppekit.png'
import user from '../../assets/image/sidebar/user.png'
import userActive from '../../assets/image/sidebar/userActive.png'
import attendce from '../../assets/image/sidebar/attendace.png'
import attendceactive from '../../assets/image/sidebar/attendace_Active.png'

import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";
import { IoAnalyticsOutline, IoSettingsOutline } from "react-icons/io5";
import { FaRegFaceSmileBeam } from "react-icons/fa6";
import { LuCodesandbox } from "react-icons/lu";

import { useLocation, useNavigate } from "react-router-dom";
import { Logo, small_logo } from "../../Helper/Constant";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useTranslation } from "react-i18next";

const Sidebar = ({ handleDrawerToggle, drawerWidth, drawerStatus }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [sideTitle, setSideTitle] = useState("");
  const [submenu, setSubmenu] = useState(false);
  const [confgSubmenu, setConfgSubmenu] = useState(false);
  const [faceSubmenu, setFaceSubmenu] = useState(false);
  const [retailfeature, setretailfeature] = useState(false);
  const [customertrafficanalysis, setcustomertrafficanalysis] = useState(false);
  const handleShowConfgSubmenu = () => {
    setConfgSubmenu((cur) => !cur);
  };
  const handleShowfaceSubmenu = () => {
    setFaceSubmenu((cur) => !cur);
  };
  const handleShowretailSubmenu = () => {
    setretailfeature((cur) => !cur);
  };
  const handleShowtrafficanalysis = () => {
    setcustomertrafficanalysis((cur) => !cur);
  };

  const userData = JSON.parse(localStorage.getItem("userData"));

  const checkMenu = (type) => {
    let data = userData && userData.user_permissions && userData.user_permissions.map((item) => {
      return item.name == type
    })
    return data ? data.includes(true) : false
  };

  const SidebarList = [
    {
      name: `${t("Dashboard")}`,
      icon: dashboard,
      activeIcon: dashboardActive,
      path: "/dashboard",
      visible: checkMenu("dashboardmanufacturing"),
    },
    {
      name: `${t("Dashboard")}`,
      icon: dashboard,
      activeIcon: dashboardActive,
      path: "/retailfeatures/dashboard",
      visible: checkMenu("dashboardretail"),
    },
    {
      name: `${t("Alert")}`,
      icon: alert,
      activeIcon: alertActive,
      path: "/alert",
      visible: checkMenu('alert')
    },
    {
      name: `${t("PPE Kit Detection")}`,
      icon: ppekit,
      activeIcon: ppekit_active,
      path: "/ppe-kit-detection",
      visible: checkMenu('alert')
    },
    // {
    //   name: "Report",
    //   icon: report,
    //   activeIcon: reportActive,
    //   path: "/report",
    //   visible: checkMenu("Manufacturer"),
    // },
    // {
    //   name: "Visitor",
    //   icon: visitor,
    //   activeIcon: visitor_active,
    //   path: "/visitor",
    //   visible: checkMenu("Manufacturer"),
    // },
    {
      name: `${t("FireDetections")}`,
      icon: FireDetections,
      activeIcon: FireDetections_active,
      path: "/fire-detections",
      visible: checkMenu("firedetection"),
    },
    {
      name: `${t("FallDetections")}`,
      icon: FallDetections,
      activeIcon: FallDetections_active,
      path: "/fall-detections",
      visible: checkMenu("falldetection"),

    },
    {
      name: `${t("DangerDetections")}`,
      icon: DangerDetections,
      activeIcon: DangerDetections_active,
      path: "/danger-detections",
      visible: checkMenu("dangerdetection"),

    },
    {
      name: `${t("IntrusionDetections")}`,
      icon: IntrusionDetections,
      activeIcon: IntrusionDetections_active,
      path: "/intrusion-detections",
      visible: checkMenu("intrusiondetection"),

    },

    {
      name: "Traffic Analysis",
      icon: analytics,
      activeIcon: analytics_active,
      path: "/retailfeatures/trafficanalysis",
      visible: checkMenu("trafficanalysis"),

    },
    {
      name: "Heat Mapping",
      icon: heatmap,
      activeIcon: heatmap_active,
      path: "/retailfeatures/heatmapping",
      visible: checkMenu("heatmapping"),

    },
    {
      name: "Queue Management",
      icon: queue,
      activeIcon: queue_active,
      path: "/retailfeatures/queuemanagement",
      visible: checkMenu("queuemanagement"),

    },
    {
      name: "Demographic Analysis",
      icon: demographicanalysis,
      activeIcon: demographicanalysis_active,
      path: "/retailfeatures/demographicanalysis",
      visible: checkMenu("demographicdetails"),

    },
    // {
    //   name: "Interaction Analysis",
    //   icon: Interaction,
    //   activeIcon: Interaction_active,
    //   path: "/retailfeatures/interactionanalysis",
    // },
    {
      name: "Safety and Compliance",
      icon: safety,
      activeIcon: safety_active,
      path: "/retailfeatures/safety&compliance",
      visible: checkMenu("safetyandcompliance"),

    },
    {
      name: "Dashboard",
      icon: dashboard,
      activeIcon: dashboardActive,
      path: "/face-reg/dashboard",
      visible: checkMenu("FaceRegDashboard"),
    },
    {
      name: "Registration",
      icon: help,
      activeIcon: help_active,
      path: "/face-reg/registration",
      visible: checkMenu("FaceRegRegistration"),
    },
    {
      name: "Attendance",
      icon: attendce,
      activeIcon: attendceactive,
      path: "/face-reg/attendance",
      visible: checkMenu("FaceRegAttendance"),
    },
    {
      name: "Close of Day",
      icon: event,
      activeIcon: eventActive,
      path: "/face-reg/close-of-day",
      visible: checkMenu("FaceRegCloseofDay"),
    },
    {
      name: "Alert",
      icon: alert,
      activeIcon: alertActive,
      path: "/face-reg/alert",
      visible: checkMenu("FaceRegAlert"),
    },
    {
      name: "Standard Management",
      icon: user,
      activeIcon: userActive,
      path: '/face-reg/standardmange',
      visible: checkMenu("FaceRegStandardManagement"),

    },
    {
      name: "User Management",
      icon: user,
      activeIcon: userActive,
      path: "/face-reg/user-manag",
      visible: checkMenu("FaceRegUserManagement"),
    },
    {
      name: "Reports",
      icon: report,
      activeIcon: reportActive,
      path: "/face-reg/reports",
      visible: checkMenu("FaceRegReports"),
    },
    // {
    //   name: "Help & Support",
    //   icon: help,
    //   activeIcon: help_active,
    //   path: "/help-support",
    // },

    {
      name: `${t("Configuration")}`,
      iconr: <IoSettingsOutline style={{ color: "#fff", fontSize: "18px" }} />,
      activeIconr: (
        <IoSettingsOutline style={{ color: "#fff", fontSize: "18px" }} />
      ),
      // path: "/userManagement",
      visible: userData && userData.user_permissions && userData.user_permissions.length > 0 && userData.user_permissions.some((item) => {
        return item.name == "camera" || item.name == "location" || item.name == "roi" || item.name == "area" || item.name == "customuser" || item.name == "cameraevent" || item.name == "organization"
      }),

      configsubmenus: [
        {
          name: `${t("Camera")}`,
          icon: camera,
          activeIcon: cameraActive,
          path: "/camera",
          visible: checkMenu("camera"),
        },
        {
          name: `${t("Location")}`,
          icon: location1,
          activeIcon: locationActive,
          path: "/location",
          visible: checkMenu("location"),
        },
        {
          name: `${t("ROI")}`,
          icon: roi,
          activeIcon: roiActive,
          path: "/roi",
          visible: checkMenu("roi"),
        },
        {
          name: `${t("Area")}`,
          icon: area,
          activeIcon: areaActive,
          path: "/area",
          visible: checkMenu("area"),
        },
        {
          name: `${t("UserManagement")}`,
          icon: user,
          activeIcon: userActive,
          path: "/user-management",
          visible: checkMenu("customuser"),
        },
        {
          name: `${t("Event")}`,
          icon: event,
          activeIcon: eventActive,
          path: "/event",
          visible: checkMenu("cameraevent"),
        },
        {
          name: `${t("Organization")}`,
          icon: help,
          activeIcon: help_active,
          path: "/organization",
          visible: checkMenu("organization"),
        },
        {
          name: `${t("Role")}`,
          icon: help,
          activeIcon: help_active,
          path: "/role",
          visible: checkMenu("group"),
        },
      ],
    },
    // {
    //   name: "Face Reg",
    //   iconr: <FaRegFaceSmileBeam style={{ color: "#fff", fontSize: "18px" }} />,
    //   activeIconr: (
    //     <FaRegFaceSmileBeam style={{ color: "#fff", fontSize: "18px" }} />
    //   ),
    //   visible: true,
    //   role: ["super-admin"],
    //   facesubmenus: [
    //     {
    //       name: "Dashboard",
    //       icon: dashboard,
    //       activeIcon: dashboardActive,
    //       path: "/face-reg/dashboard",
    //     },
    //     {
    //       name: "Registration",
    //       icon: help,
    //       activeIcon: help_active,
    //       path: "/face-reg/registration",
    //     },
    //     {
    //       name: "Attendance",
    //       icon: attendce,
    //       activeIcon: attendceactive,
    //       path: "/face-reg/attendance",
    //     },
    //     {
    //       name: "Close of Day",
    //       icon: event,
    //       activeIcon: eventActive,
    //       path: "/face-reg/close-of-day",
    //     },
    //     {
    //       name: "Alert",
    //       icon: alert,
    //       activeIcon: alertActive,
    //       path: "/face-reg/alert",
    //     },
    //     {
    //       name: "Standard Management",
    //       icon: user,
    //       activeIcon: userActive,
    //       path: '/face-reg/standardmange'

    //     },
    //     {
    //       name: "User Management",
    //       icon: user,
    //       activeIcon: userActive,
    //       path: "/face-reg/user-manag",
    //     },
    //     {
    //       name: "Reports",
    //       icon: report,
    //       activeIcon: reportActive,
    //       path: "/face-reg/reports",
    //     },
    //   ],
    // },


  ];

  console.log("userData", SidebarList);


  return (
    <Box width={drawerWidth} sx={{ transition: "width 0.3s ease-in-out" }}>
      <List
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "inherit",
          zIndex: 10,
          backgroundColor: "#f151515",
          padding: drawerStatus ? "26px 16px 14px" : "24px 16px 14px",
        }}
      >
        {drawerStatus ? (
          <img src={Logo} alt="main_logo" width={"120"} />
        )
          : (
            <img src={small_logo} alt="main_logo" width={"28"} />
          )}
      </List>
      {/* <Divider sx={{ borderColor: "#333" }} /> */}
      <Box marginTop={2} >


        {SidebarList.map((data, index) => (

          data && data.visible && (<List
            sx={{
              borderLeft: ` ${location.pathname === data.path
                ? "2px solid #05EE3B !important"
                : "2px solid transparent !important"
                }`,
              padding: "0",
            }}
          >
            <ListItem
              onClick={() => navigate(data.path)}
              key={data}
              disablePadding
              sx={{ margin: "5px 0", width: "unset" }}
            >
              <ListItemButton
                onClick={
                  data?.name === t("Configuration")
                    ? handleShowConfgSubmenu
                    : data?.name === "Face Reg"
                      ? handleShowfaceSubmenu
                      : data?.name === "Retail Features"
                        ? handleShowretailSubmenu
                        : data?.name === "Customer Traffic Analysis"
                          ? handleShowtrafficanalysis
                          : handleDrawerToggle
                }
                key={index}
                sx={{ borderRadius: "0px", padding: "9px 17px", fontSize: "14px" }}
                className={
                  location.pathname === data.path ? "active_sidebar" : ""
                }
              >
                <ListItemIcon
                  sx={{
                    minWidth: 30,
                    width: "13px",
                    height: "16px",
                    transition: "width 0.3s ease-in-out",
                  }}
                >
                  {data?.iconr ? (
                    data?.iconr
                  ) : (
                    <img
                      src={
                        location.pathname === data.path
                          ? data.activeIcon
                          : data.icon
                      }
                      alt=""
                    />
                  )}
                </ListItemIcon>
                {drawerStatus && (
                  <ListItemText
                    primary={data.name}
                    className="sidebar_text"
                    sx={{
                      margin: "0",
                      transition: "width 0.3s ease-in-out",
                      display: drawerStatus ? "block" : "none",
                      color: `${location.pathname === data.path ? "#05EE3B" : "#fff"
                        }`,
                      fontSize: "14px",
                    }}
                  />
                )}
                {data?.name === t("Configuration") ? (
                  confgSubmenu ? (
                    <IoIosArrowDown style={{ color: "#fff" }} />
                  ) : (
                    <IoIosArrowForward style={{ color: "#fff" }} />
                  )
                ) : (
                  ""
                )}
                {data?.name === "Face Reg" ? (
                  faceSubmenu ? (
                    <IoIosArrowDown style={{ color: "#fff" }} />
                  ) : (
                    <IoIosArrowForward style={{ color: "#fff" }} />
                  )
                ) : (
                  ""
                )}
                {data?.name === "Retail Features" ? (
                  retailfeature ? (
                    <IoIosArrowDown style={{ color: "#fff" }} />
                  ) : (
                    <IoIosArrowForward style={{ color: "#fff" }} />
                  )
                ) : (
                  ""
                )}
              </ListItemButton>
            </ListItem>
            {confgSubmenu &&
              data?.configsubmenus?.map((data, i) => (
                data?.visible && <List
                  sx={{
                    borderLeft: ` ${location.pathname === data.path
                      ? "2px solid #05EE3B !important"
                      : "2px solid transparent !important"
                      }`,
                    padding: "0",
                  }}
                >
                  <ListItem
                    onClick={() => navigate(data.path)}
                    key={data}
                    disablePadding
                    sx={{ margin: "5px 0", width: "unset" }}
                  >
                    <ListItemButton
                      onClick={handleDrawerToggle}
                      key={index}
                      sx={{ borderRadius: "0px", padding: "9px17px", paddingLeft: !drawerStatus ? "17px" : "45px" }}
                      className={
                        location.pathname === data.path
                          ? "active_sidebar"
                          : ""
                      }
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 30,
                          width: "13px",
                          height: "16px",
                          transition: "width 0.3s ease-in-out",
                        }}
                      >
                        {data?.iconr ? (
                          data?.iconr
                        ) : (
                          <img
                            src={
                              location.pathname === data.path
                                ? data.activeIcon
                                : data.icon
                            }
                            alt=""
                          />
                        )}
                      </ListItemIcon>
                      {drawerStatus && (
                        <ListItemText
                          primary={data.name}
                          className="sidebar_text"
                          sx={{
                            margin: "0",
                            transition: "width 0.3s ease-in-out",
                            display: drawerStatus ? "block" : "none",
                            color: `${location.pathname === data.path
                              ? "#05EE3B"
                              : "#fff"
                              }`,
                            fontSize: "15px",
                          }}
                        />
                      )}
                      {data?.name === t("Configuration") ? (
                        submenu ? (
                          <IoIosArrowDown style={{ color: "#fff" }} />
                        ) : (
                          <IoIosArrowForward style={{ color: "#fff" }} />
                        )
                      ) : (
                        ""
                      )}
                    </ListItemButton>
                  </ListItem>
                </List>
              ))}

            {faceSubmenu &&
              data?.facesubmenus?.map((data, i) => {
                return (
                  <List
                    sx={{
                      borderLeft: ` ${location.pathname === data.path
                        ? "2px solid #05EE3B !important"
                        : "2px solid transparent !important"
                        }`,
                      padding: "0",
                    }}
                  >
                    <ListItem
                      onClick={() => navigate(data.path)}
                      key={data}
                      disablePadding
                      sx={{ margin: "5px 0", width: "unset" }}
                    >
                      <ListItemButton
                        onClick={handleDrawerToggle}
                        key={index}
                        sx={{ borderRadius: "0px", padding: "9px17px", paddingLeft: !drawerStatus ? "17px" : "45px" }}
                        className={
                          location.pathname === data.path
                            ? "active_sidebar"
                            : ""
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 30,
                            width: "13px",
                            height: "16px",
                            transition: "width 0.3s ease-in-out",
                          }}
                        >
                          {data?.iconr ? (
                            data?.iconr
                          ) : (
                            <img
                              src={
                                location.pathname === data.path
                                  ? data.activeIcon
                                  : data.icon
                              }
                              alt=""
                            />
                          )}
                        </ListItemIcon>
                        {drawerStatus && (
                          <ListItemText
                            primary={data.name}
                            className="sidebar_text"
                            sx={{
                              margin: "0",
                              transition: "width 0.3s ease-in-out",
                              display: drawerStatus ? "block" : "none",
                              color: `${location.pathname === data.path
                                ? "#05EE3B"
                                : "#fff"
                                }`,
                              fontSize: "15px",
                            }}
                          />
                        )}
                        {data?.name === "Face Reg" ? (
                          submenu ? (
                            <IoIosArrowDown style={{ color: "#fff" }} />
                          ) : (
                            <IoIosArrowForward style={{ color: "#fff" }} />
                          )
                        ) : (
                          ""
                        )}
                      </ListItemButton>
                    </ListItem>
                  </List>
                );
              })}
            {retailfeature &&
              data?.retailfeatures?.map((data, i) => {
                return (
                  <List
                    sx={{
                      borderLeft: ` ${location.pathname === data.path
                        ? "2px solid #05EE3B !important"
                        : "2px solid transparent !important"
                        }`,
                      padding: "0",
                    }}
                  >
                    <ListItem
                      onClick={() => navigate(data.path)}
                      key={data}
                      disablePadding
                      sx={{ margin: "5px 0", width: "unset" }}
                    >
                      <ListItemButton
                        onClick={handleDrawerToggle}
                        key={index}
                        sx={{ borderRadius: "0px", padding: "9px17px", paddingLeft: !drawerStatus ? "17px" : "45px" }}
                        className={
                          location.pathname === data.path
                            ? "active_sidebar"
                            : ""
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 30,
                            width: "13px",
                            height: "16px",
                            transition: "width 0.3s ease-in-out",
                          }}
                        >
                          {data?.iconr ? (
                            data?.iconr
                          ) : (
                            <img
                              src={
                                location.pathname === data.path
                                  ? data.activeIcon
                                  : data.icon
                              }
                              alt=""
                            />
                          )}
                        </ListItemIcon>
                        {drawerStatus && (
                          <ListItemText
                            primary={data.name}
                            className="sidebar_text"
                            sx={{
                              margin: "0",
                              transition: "width 0.3s ease-in-out",
                              display: drawerStatus ? "block" : "none",
                              color: `${location.pathname === data.path
                                ? "#05EE3B"
                                : "#fff"
                                }`,
                              fontSize: "15px",
                            }}
                          />
                        )}
                        {data?.name === "Retail Features" ? (
                          submenu ? (
                            <IoIosArrowDown style={{ color: "#fff" }} />
                          ) : (
                            <IoIosArrowForward style={{ color: "#fff" }} />
                          )
                        ) : (
                          ""
                        )}
                      </ListItemButton>
                    </ListItem>
                  </List>
                );
              })}

          </List>)


        ))}
      </Box>
    </Box>
  );
};

export default Sidebar;
